export enum LOCAL_STORE_KEYS {
    PAGE_STATES = 'page-status',
    EDITE_ADDRESS = 'edite-address',
    BP_DATA = 'bp-data',
    BP_ADDRESS = 'bp-address',
    SELECTED_ADDRESS = 'selected-address',
    SELECTED_INDEX = 'selected-index'
}

export enum PAGE_STATES {
    LOADING = 'loading',
    MUTATE = 'mutate'
}
