import _ from "lodash";
import {isArray} from "@dladio/utils";
import {TextField} from "@fluentui/react";
import {useLocalStore} from "@dladio/hooks";
import {LOCAL_STORE_KEYS} from "../const/local-store.const";
import {AutoCompleteTextField, LabelOnLeft} from "@dladio/core-ui";

const EditeForm = () => {
    const {select, setStore}: any = useLocalStore(LOCAL_STORE_KEYS.EDITE_ADDRESS)
    const selectedAddressIndex = isArray(select(LOCAL_STORE_KEYS.BP_ADDRESS)) ? select(LOCAL_STORE_KEYS.BP_ADDRESS).findIndex((item: any) => (item?.RowNum == select(LOCAL_STORE_KEYS.SELECTED_INDEX))) : 0
    const selectedAddress = isArray(select(LOCAL_STORE_KEYS.BP_ADDRESS)) ? select(LOCAL_STORE_KEYS.BP_ADDRESS)[selectedAddressIndex] : {}

    const onChangeHandler = async (event: any) => {
        const {id, value}: any = event.target
        const bpAddress: any = _.cloneDeep(select(LOCAL_STORE_KEYS.BP_ADDRESS))
        bpAddress[selectedAddressIndex][id] = value
        setStore(LOCAL_STORE_KEYS.BP_ADDRESS, bpAddress)
    }

    const onChangeAutoComplete = (id: any, value: any) => {
        const bpAddress: any = _.cloneDeep(select(LOCAL_STORE_KEYS.BP_ADDRESS))
        bpAddress[selectedAddressIndex][id] = value
        setStore(LOCAL_STORE_KEYS.BP_ADDRESS, bpAddress)
    }

    return (
        <>
            <div className={'pt-2'}>
                <LabelOnLeft label={'Address Name'} className={'font-[Poppins]'} >
                    <TextField id={'AddressName'} className={'w-[300px] font-[Poppins]'}  value={selectedAddress?.AddressName}
                            disabled={!selectedAddress}
                            borderless={!selectedAddress}
                            onChange={onChangeHandler}/>
                </LabelOnLeft>
                <LabelOnLeft label={'Address To'} className={'font-[Poppins]'}>
                    <TextField id={'U_AddressTo'} className={'w-[300px] font-[Poppins]'} value={selectedAddress?.U_AddressTo}
                            disabled={!selectedAddress}
                            borderless={!selectedAddress}
                            onChange={onChangeHandler}
                    />
                </LabelOnLeft>
                <LabelOnLeft label={'Street'} className={'font-[Poppins]'}>
                    <TextField id={'Street'} className={'w-[300px] font-[Poppins]'} value={selectedAddress?.Street}
                            disabled={!selectedAddress}
                            borderless={!selectedAddress}
                            onChange={onChangeHandler}
                    />
                </LabelOnLeft>
                <LabelOnLeft label={'City'} className={'font-[Poppins]'}>
                    <TextField id={'City'} className={'w-[300px] font-[Poppins]'} value={selectedAddress?.City}
                            disabled={!selectedAddress}
                            borderless={!selectedAddress}
                            onChange={onChangeHandler}/>
                </LabelOnLeft>
                <LabelOnLeft label={'Postal Code'} className={'font-[Poppins]'}>
                    <TextField id={'ZipCode'} className={'w-[300px] font-[Poppins]'} value={selectedAddress?.ZipCode}
                            disabled={!selectedAddress}
                            borderless={!selectedAddress}
                            onChange={onChangeHandler}/>
                </LabelOnLeft>
                <LabelOnLeft label={'State'} className={'font-[Poppins]'}>
                    <div className={'w-[300px]'}>
                        <AutoCompleteTextField
                            disabled={!selectedAddress}
                            id={'State'}
                            defaultKey={selectedAddress?.State ? selectedAddress.State : ''}
                            onChange={(id: any, value: any) => {
                                onChangeAutoComplete(id, value)
                            }}
                            options={{type: "erp", query: {name: 'b4f7a2dc33'}}}
                        />
                    </div>
                </LabelOnLeft>
                <LabelOnLeft label={'Country'} className={'font-[Poppins]'}>
                    <div className={'w-[300px]'}>
                        <AutoCompleteTextField
                            disabled={!selectedAddress}
                            keyValuePair={{key: 'Code', text: 'Name'}}
                            defaultKey={selectedAddress?.Country ? selectedAddress?.Country : ''}
                            id={'Country'}
                            onChange={(id: any, value: any) => {
                                onChangeAutoComplete(id, value)
                            }}
                            options={{type: "erp", query: {name: '460e2f689f'}}}
                        />
                    </div>
                </LabelOnLeft>
            </div>
        </>
    )
}

export default EditeForm