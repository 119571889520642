import { CoreUIProvider } from "@dladio/core-ui";
import AddressApp from "./helios-address-app/address-app";
import { AppBridgeProvider } from "@dladio/app-bridge";
import ConfirmDialog from "./helios-address-app/components/confirmation-dialog";
function App() {
    return (
        <AppBridgeProvider apiKey="bp-address">
            <CoreUIProvider>
                <AddressApp />
                <ConfirmDialog />
            </CoreUIProvider>
        </AppBridgeProvider>
    );
}

export default App;
