import _ from "lodash";
import Loading from "./components/loading";
import ItemCell from "./components/item-cell";
import {isArray} from "@dladio/utils";
import EditeForm from "./components/edite-form";
import {useDispatch} from "react-redux";
import {useLocalStore} from "@dladio/hooks";
import {Dropdown, List} from "@fluentui/react";
import {EXEC_SAP_SERVICE} from "@dladio/service/dist/const/erp-method.consts";
import {showConfirmDialog} from "../store/reducers/confirmationDialog";
import {useEffect, useState} from "react";
import {exeMethod, exeMutation} from "./actions/actions";
import {Box, DefaultCommandBar, Stack} from "@dladio/core-ui";
import {LOCAL_STORE_KEYS, PAGE_STATES} from "./const/local-store.const";
import {dispatchAction, useAppBridgeSelector} from "@dladio/app-bridge";
import {middleErrorToast, middleSuccessToast} from "../services/toast.service";

const AddressApp = () => {
    const dispatch = useDispatch()
    const {model}: any = useAppBridgeSelector()
    const {select, setStore}: any = useLocalStore(LOCAL_STORE_KEYS.EDITE_ADDRESS)
    const [addressType, setAddressType]: any = useState('bo_BillTo')
    const {model: PSModel, setStore: setPageState} = useLocalStore(LOCAL_STORE_KEYS.PAGE_STATES)

    const isLoading = _.get(PSModel, PAGE_STATES.LOADING)
    const listItems = isArray(select(LOCAL_STORE_KEYS.BP_ADDRESS)) ? select(LOCAL_STORE_KEYS.BP_ADDRESS).filter((item: any) => (item?.AddressType == addressType)) : []

    useEffect(() => {

        if (model?.params?.id) {
            setPageState(PAGE_STATES.LOADING, true)
            dispatch(exeMethod(EXEC_SAP_SERVICE, {
                method: "GET",
                endpoint: `BusinessPartners('${model?.params?.id}')`,
                body: {
                    "id": model?.params?.id
                }
            }) as any).then((data: any) => {
                setStore(LOCAL_STORE_KEYS.BP_DATA, data?.message)
                setStore(LOCAL_STORE_KEYS.BP_ADDRESS, data?.message?.BPAddresses)
            }).catch(() => {
                middleErrorToast("Addresses cannot load")
            }).finally(() => setPageState(PAGE_STATES.LOADING, false))
        }

    }, [model?.params?.id])

    const onChangeHandler = (event: any, {key, Text}: any) => {
        setAddressType(key)
    }

    const onSaveHandler = async () => {
        setPageState(PAGE_STATES.LOADING, true)
        dispatch(exeMutation(EXEC_SAP_SERVICE, {
            method: "PATCH",
            endpoint: `BusinessPartners('${model?.params?.id}')`,
            body: {
                BPAddresses: [...select('bp-address')]
            }
        }) as any).then(() => {
            middleSuccessToast('Updated successfully')
        }).catch((error: any) => {
            middleErrorToast("Failed to save",error?.data?.message?.message?.value)
        }).finally(() => setPageState(PAGE_STATES.LOADING, false))
    }

    const onRenderCell = (item: any, index: any) => {

        return (
            <ItemCell item={item} index={index}/>
        );
    }

    return (
        <>
            <DefaultCommandBar actions={[
                {
                    key: 1,
                    text: 'Save',
                    iconProps: {
                        iconName: 'Save',
                    },
                    onClick: () => {
                        dispatch(
                            showConfirmDialog({
                                title: "Confirmation Required!",
                                subtitle: "Are you sure you want to continue with the actions",
                                onConfirm: () => {
                                    onSaveHandler()
                                },
                            })
                        );
                    }
                },
                {
                    key: 2,
                    text: 'Create New Address',
                    iconProps: {
                        iconName: 'AddNotes',
                    },
                    onClick: () => {
                        dispatchAction({
                            action: 'navigate',
                            payload: `page/229d9e7440?id=${model?.params?.id}`
                        })
                    }
                }
            ]}/>

            <Stack>
                <Box className={'w-[35%] [&>div]:h-screen'}>
                    <div className={'bg-white rounded-2 pt-2 space-y-2.5'}>
                        <Dropdown
                            label="Select Type"
                            options={[{key: 'bo_BillTo', text: 'billing'}, {
                                key: 'bo_ShipTo',
                                text: 'shipping'
                            }]}
                            defaultSelectedKey={addressType}
                            onChange={onChangeHandler}
                            styles={{
                                dropdown: {width: 150},
                            }}
                        />

                        {
                            isLoading ? <Loading/> : <List
                                items={listItems}
                                onRenderCell={onRenderCell}/>
                        }
                    </div>
                </Box>
                <Box className={'flex-grow [&>div]:h-screen'}>
                    {
                        isLoading ? <Loading/> : <EditeForm/>
                    }
                </Box>
            </Stack>
        </ >
    );
}
export default AddressApp