import {Text} from '@fluentui/react/lib/Text';
import {useLocalStore} from "@dladio/hooks";
import {LOCAL_STORE_KEYS} from "../const/local-store.const";

const ItemCell = ({item, index}: any) => {
    const {select, setStore}: any = useLocalStore(LOCAL_STORE_KEYS.EDITE_ADDRESS)

    return (
        <div key={index} onClick={() => {
            setStore(LOCAL_STORE_KEYS.SELECTED_INDEX, item?.RowNum)
            setStore(LOCAL_STORE_KEYS.SELECTED_ADDRESS, {...item})
        }}
             className={`py-1 cursor-pointer px-2 gap-2 flex flex-row border-gray border-b hover:bg-gray-100 ${select('selected-index') == item?.RowNum ? 'bg-gray-100' : ''} `}>
            <div className={'flex flex-col flex-1 py-1'}>
                <div className={'flex flex-row flex-1'}>
                    <Text variant={'small'} className={'font-[Poppins]'}>{item?.AddressName}</Text>
                </div>
            </div>
        </div>
    )
}

export default ItemCell